import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFCard';
import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFEmptyCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { CancelWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CancelWithConfirmation';
import {
    getEntityFilterReqParams,
    getFilterPreRequestParams,
    getFiltersMetadataByViewType,
    getRequestBody,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import {
    checkIfFilterInfoIsAvailable,
    noInformationAvailableCardStyles,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/utils';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { BasicForm } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/BasicForm';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { replaceAllPlaceholdersInObject } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box } from '@mui/material';
import _, { cloneDeep, intersection, merge } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query/types/react/types';
import { ScreenerData } from '../types/screener';
import { roundedTextButtonThemeV2BorderRadius4 } from '../../ETFButton/ButtonsThemes';
import { CreateFormProps } from './CreateForm';
import { RequestTypes, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { OperationTypes } from '@cfra-nextgen-frontend/shared/src/utils';

let formStateValues: any = {};

export function AddForm({
    analyticsCardName,
    entityType,
    entityId,
    cancelButtonCallback,
    openConfirmationModal,
    setOpenConfirmationModal,
    submitButtonName,
    operationType,
    presetValues,
    localFiltersMetadata,
    useScreenerDataForFilters,
    onSubmit,
}: CreateFormProps) {
    const [formData, setFormData] = useState<RhFormData | undefined>();
    const [filtersData, setFiltersData] = useState<FiltersData>();
    const [requestBody, setRequestBody] = useState({} as any);
    const [formDataState, setFormDataState] = useState<Record<string, any>>();

    const { formState, control, getValues, setValue, handleSubmit, trigger, reset, resetField } = useForm({
        reValidateMode: 'onSubmit',
    });

    const { getFiltersData, getScreenerData } = useContext(ProjectSpecificResourcesContext);

    const filterPreRequestParams = useMemo(
        () => getFilterPreRequestParams(entityType, operationType, entityId),
        [entityType, operationType, entityId],
    );

    const screenerQueryResult = getScreenerData?.({
        ...filterPreRequestParams,
        config: {
            enabled: Object.keys(filterPreRequestParams).length > 0,
        },
    }) as UseQueryResult<ScreenerData>;

    const filterPreRequestParamValues = useMemo(() => {
        if (!(screenerQueryResult?.data?.results?.data && screenerQueryResult.data.results.data.length > 0)) {
            return {};
        }

        return {
            filterPreRequestParamValues: screenerQueryResult.data.results.data[0],
        };
    }, [screenerQueryResult.data?.results.data]);

    const entityFiltersReqParams: SearchByParams = useMemo(() => {
        let params = replaceAllPlaceholdersInObject(
            getEntityFilterReqParams(entityType, operationType),
            filterPreRequestParamValues,
            true,
        );

        if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType===OperationTypes.BULK_CREATE) {
            if (params.requestBody.filters.values['user_management.group.account_id'].values[0] === undefined) {
                params.requestBody.filters.values['user_management.group.account_id'].values[0] = -1;
            }
        }

        return params;
    }, [filterPreRequestParamValues, entityType, operationType]);

    
    if (
        Object.keys(requestBody).length > 0 && presetValues && Object.keys(presetValues).length > 0
            ? true
            : Object.keys(formState.dirtyFields).length > 0 &&
                intersection(Object.keys(requestBody), Object.keys(formState.dirtyFields)).length > 0
    ) {
        entityFiltersReqParams['requestBody'] = getRequestBody(
            requestBody,
            {
                ...formState.dirtyFields,
                ...Object.keys(presetValues || {})?.reduce((acc, value) => {
                    acc[value] = true;
                    return acc;
                }, {} as any),
            },
            getValues,
            entityType,
            replaceAllPlaceholdersInObject(
                getEntityFilterReqParams(entityType, operationType),
                filterPreRequestParamValues,
                true,
            )
        );
    }

    const filtersQueryResult = getFiltersData?.(entityFiltersReqParams) as UseQueryResult<FiltersData>;

    useEffect(() => {
        if (!filtersQueryResult.data) {
            return;
        }

        if (localFiltersMetadata && Object.keys(localFiltersMetadata).length > 0) {
            const filtersQueryResultDataDeepCopy = cloneDeep(filtersQueryResult.data);
            setFiltersData({
                ...filtersQueryResultDataDeepCopy,
                filter_metadata: merge(filtersQueryResultDataDeepCopy.filter_metadata, localFiltersMetadata),
            });
            return;
        }

        if (
            filtersQueryResult?.data?.filter_metadata &&
            screenerQueryResult?.data?.results?.data?.length &&
            useScreenerDataForFilters
        ) {
            const filtersDataCopy: FiltersData = _.cloneDeep(filtersQueryResult.data || {});
            const { filtersMetadata } = getFiltersMetadataByViewType({
                screenerData: screenerQueryResult.data,
                filtersMetadata: filtersDataCopy?.filter_metadata,
            });

            filtersDataCopy['filter_metadata'] = filtersMetadata;

            setFiltersData(filtersDataCopy);
        } else {
            setFiltersData(filtersQueryResult.data);
        }
    }, [filtersQueryResult.data, screenerQueryResult?.data, localFiltersMetadata, useScreenerDataForFilters]);

    const handleDependencies = useMemo(
        () => Object.values(filtersQueryResult.data?.filter_metadata || {}).some((item) => Boolean(item.dependencies)),
        [filtersQueryResult.data?.filter_metadata],
    );

    const triggerHandleDependencies = useCallback(() => {
        if (!filtersQueryResult.data) {
            return;
        }

        let metaData = filtersQueryResult.data.filter_metadata;
        let request_body = {} as any;

        for (let key in metaData) {
            if ((metaData[key].dependencies || []).length > 0) {
                let new_key = combineIntoFormElementName({
                    componentName: metaData[key].component,
                    filterMetadataKey: key,
                });
                request_body[new_key] = {
                    query_arg: metaData[key].source_field || '',
                    resetFields: metaData[key].dependencies?.map((item) =>
                        combineIntoFormElementName({
                            componentName: metaData[item].component,
                            filterMetadataKey: item,
                        }),
                    ),
                };
            }
        }

        setRequestBody(request_body);
    }, [filtersQueryResult.data]);

    useEffect(() => {
        if (!handleDependencies) {
            return;
        }

        triggerHandleDependencies();
    }, [handleDependencies, triggerHandleDependencies, filtersQueryResult.data]);

    useEffect(() => {
        if (presetValues && Object.keys(presetValues).length > 0) {
            reset(presetValues);
            triggerHandleDependencies(); // re-handle dependencies after preset values are set
        }
    }, [reset, presetValues, triggerHandleDependencies]);

    const clearChildControlsOnParentChange = useCallback(
        (fieldName?: string) => {
            if (fieldName !== undefined && fieldName in requestBody) {
                for (let field of requestBody[fieldName].resetFields) {
                    if (field in formState.dirtyFields) {
                        resetField(field);
                    }
                }
            }
        },
        [requestBody, formState.dirtyFields, resetField],
    );

    const onCancel = useCallback(
        (resetAllValues: boolean = true) => {
            cancelButtonCallback();
            formStateValues = {}; //reset formState values on confirm/cancel of modal popups
            //TODO: Need to find correct way to reset, this hangs modal form sometime.
            // if (formState.isDirty || Object.keys(formState.errors).length > 0) {
            //     if (resetAllValues) reset();
            //     else reset(presetValues || formData);
            // }
        },
        [cancelButtonCallback, formState.isDirty, reset, formState.errors, formData, presetValues],
    );

    const enableSubmitButton = useMemo(
        () => Boolean(formState.isDirty && formState.isValid),
        [formState.isDirty, formState.isValid],
    );

    const filterInfoNotAvailable = useMemo(
        () => checkIfFilterInfoIsAvailable(filtersQueryResult.isLoading, filtersQueryResult.data),
        [filtersQueryResult.isLoading, filtersQueryResult.data],
    );

    const isDirty = useMemo(() => {
        //we want preset values that are included in the response to be part of the 'isDirty' check.
        const filteredPresetValues = presetValues ? Object.entries(presetValues).filter(([key, value]) => !value?.isDirty).map(([key])=>key): [];

        //In case any values in formDataState.POST
        const hasAdditionData = !!(formDataState && 
            Object.keys(formDataState).map((key) => {
                return (formDataState?.[key]?.[RequestTypes.POST]?.length || 0);
            }).reduce((length, l) => { return length += l }));

        return (
            formState.isDirty &&
            Object.keys(formState.dirtyFields).filter((key) => !filteredPresetValues.includes(key)).length >
                0
        ) || hasAdditionData;

    }, [presetValues, formState.dirtyFields, formState.isDirty, formDataState]);

    const emitDefaultValues = useCallback(
        (value: any) => {
            // we need to reset form to set default values again once form is initialize.
            // https://stackoverflow.com/questions/64306943/defaultvalues-of-react-hook-form-is-not-setting-the-values-to-the-input-fields-i

            if (Object.keys(value).length > 0) {
                let callReset = false;
                for (let i = 0; i < Object.keys(value).length; i++) {
                    const key = Object.keys(value)[i];
                    if (!_.isEqual(value[key], formStateValues[key])) {
                        callReset = true;
                        break;
                    }
                }
                if (callReset) {
                    formStateValues = { ...formStateValues, ...value };
                    if (Object.keys(formStateValues).length > 0) {
                        reset(formStateValues);
                    }
                }
            }
        },
        [reset],
    );

    if (!filtersData && filtersQueryResult.isLoading) {
        return <ETFCard isLoading={filtersQueryResult.isLoading} />;
    }

    if (!filtersData || filterInfoNotAvailable) {
        return <NoInformationAvailable sx={noInformationAvailableCardStyles} />;
    }

    const emitControlValues = (value: any) => {
        setFormDataState((prevFormDataState) => ({ ...prevFormDataState, ...value }));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
            <BasicForm
                filtersData={filtersData}
                analyticsCardName={`${analyticsCardName} Form`}
                getValues={getValues}
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                setFormData={setFormData}
                trigger={trigger}
                onChangeClearHandler={clearChildControlsOnParentChange}
                emitDefaultValues={emitDefaultValues}
                emitControlValues={emitControlValues}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '1rem',
                    paddingX: 3.75,
                    paddingTop: 1,
                    paddingBottom: 4,
                }}>
                <CancelWithConfirmation
                    confirmModalText='Your changes will not be saved. Proceed?'
                    isDirty={isDirty}
                    analyticsCardName={`${analyticsCardName}CancelConfirm`}
                    onCancel={onCancel}
                    externalOpenConfirmationModal={openConfirmationModal}
                    setExternalOpenConfirmationModal={setOpenConfirmationModal}
                />
                <RoundedTextButton
                    type='submit'
                    theme={roundedTextButtonThemeV2BorderRadius4}
                    buttonText={submitButtonName}
                    disabled={!enableSubmitButton}
                    onClickCallback={() => {
                        formData &&
                            formDataState &&
                            filtersQueryResult?.data &&
                            onSubmit?.(filtersQueryResult.data, formData, formDataState);
                    }}
                />
            </Box>
        </Box>
    );
}
