import { AgGridProps, defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { applyCustomFilterValueFormatter } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { EditButtonsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/EditButtons';
import {
    getModifyDeleteRequestFunction,
    Modal,
    RowLevelFiltersConfig,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import {
    getFilterKeysToRowLevelFilter,
    getObjectWithFilter,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/rowFilters';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { FiltersModalOpenButton } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalOpenButton';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { SearchInput } from '@cfra-nextgen-frontend/shared/src/components/Screener/tickerSearch/SearchInput';
import { DataItem, ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { ssrmMaxRowsToFetch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { SearchByParams, invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SxProps } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { SnackbarProvider } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormSetValue, FieldValues } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { ResultsAndExportRow } from './ResultsAndExportRow';
import { getRowHeightBasedOnListData } from './utils';
import { AgGridRowClassRules } from '../utils/enum';
import { OperationTypes, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { FiltersData } from '../../Form/types/filters';
import { modifyRequestBodyFromFilterMetadata } from '../utils/form';
import { ColumnDef } from '../../AgGrid/types';

export type ScreenerCardProps = {
    cardName: string;
    modals?: Array<Modal>;
    searchPlaceholder?: string;
    enableTopLeftActionPanel?: boolean;
    enableTopRightActionPanel?: boolean;
    useSSRMode?: boolean;
    screenerRequestParams?: SearchByParams;
    rowLevelFiltersConfig?: RowLevelFiltersConfig;
    analyticsCreateModalFormName?: string;
    maxGridContainerHeightPercentage?: AgGridProps['maxGridContainerHeightPercentage'];
    unlimitedCalculatedHeight?: AgGridProps['unlimitedCalculatedHeight'];
    showSelectionOnEdit?: boolean;
    EditButtonsVariant?: React.ComponentType<any>;
    processDeleteAction?: (setValue: UseFormSetValue<FieldValues>, selectedIds: string[], rowLevelFiltersFormKeys?: Record<string, Record<string, string>>) => void;
    processAddAction?: (rowsData: DataItem[], externalFormData: { filtersData: FiltersData, formData?: RhFormData, formDataState?: Record<string, any> }) => { dataToAdd: any[], hasUpdate?: boolean };
    getRowClassRules?: (ruleType: AgGridRowClassRules, selectedIds: string[], rowData: any) => boolean;
    getConfirmationModalContent?: (rowData?: DataItem[], rowsToBeDeleted?: string[]) => React.ReactNode;
    getFormDataState?: (rowData?: DataItem[], additonData?: {
        rowsToBeAdded?: any
        externalFormData?: { filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any> }
    }, formData?: RhFormData) => { 
        state: Record<string, any>, deletion: string[], addition: any, updatedRowsData?: DataItem[], isRowsDataUpdated: boolean 
    };
    getDefaultView?: (rowsData: DataItem[], formData: RhFormData) => { defaultRowsData?: DataItem[], defaultFormData: RhFormData };
    getColumnOrderderedInEditMode?: (columnDef: ColumnDef[]) => ColumnDef[];
    operationType?: OperationTypes;
    entityType?: UserPlatformManagementEntityTypes;
    externalFormData?: { filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any> };
    entityId?: number;
    onCancel?: () => void;
    hardRefreshOnUpdate?: boolean; // If required to be reload the page on update (due to lot of state changes)
    setInitialEditStatus?:(rowsData: DataItem[]) => DataItem[] 
};

const topRightActionPanelStyles: SxProps = { alignItems: 'end' };
const topLeftActionPanelStyles: SxProps = { paddingBottom: '17px' };
const view = 'default';

export function ScreenerCard({
    cardName,
    modals,
    searchPlaceholder = '',
    useSSRMode = true,
    screenerRequestParams = {},
    rowLevelFiltersConfig,
    enableTopLeftActionPanel = true,
    enableTopRightActionPanel = true,
    maxGridContainerHeightPercentage,
    unlimitedCalculatedHeight,
    showSelectionOnEdit = false,
    EditButtonsVariant = EditButtonsVariant1,
    processDeleteAction,
    processAddAction,
    getRowClassRules,
    getConfirmationModalContent,
    getFormDataState,
    getDefaultView,
    getColumnOrderderedInEditMode,
    operationType,
    entityType,
    externalFormData,
    entityId,
    onCancel,
    hardRefreshOnUpdate,
    setInitialEditStatus,
}: ScreenerCardProps) {
    const {
        chipStateManager: { chipStateDispatcher },
    } = useContext(ResultsContext);

    const rowLevelFiltersConfigEnabled = useMemo(() => {
        return Boolean(rowLevelFiltersConfig && Object.keys(rowLevelFiltersConfig?.requestParams || {}).length > 0);
    }, [rowLevelFiltersConfig]);

    const [defaultValues, setDefaultValues] = useState<Record<string, any>>();
    const [gridRowsData, setGridRowsData] = useState<DataItem[]>();
    const [rowLevelFiltersFormKeys, setRowLevelFiltersFormKeys] = useState<Record<string, Record<string, string>>>();
    const [rowsToBeDeleted, setRowsToBeDeleted] = useState<string[]>();
    const [rowsToBeAdded, setRowsToBeAdded] = useState<any[]>();
    const [formDataState, setFormDataState] = useState<Record<string, any>>();
    const [gridColumnDef, setGridColumnDef] = useState<ColumnDef[]>();

    const { handleSubmit, formState, getValues, setValue, trigger, control, reset } = useForm({
        // defaultValues, // if set defaultValues, it doesn't work on defaultValues change
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    // handle changing defaultValues
    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues]);

    const { searchTerm } = useContext(FiltersModalContext);
    const [enableEdit, setEnableEdit] = useState(false);
    const [formData, setFormData] = useState<RhFormData>();
    const { getScreenerData, getDataSource, getSsrDataExportFn, getFiltersData } = useContext(
        ProjectSpecificResourcesContext,
    );

    const submitHandler: () => void = useCallback(() => handleSubmit((data) => setFormData(data))(), [handleSubmit]);

    const rowLevelFiltersDataQueryResult = getFiltersData?.({
        ...rowLevelFiltersConfig?.requestParams,
        config: {
            enabled: rowLevelFiltersConfigEnabled,
        },
        noErrorOnNoKeyValuePairs: true,
    });

    const extendedScreenerRequestParams = useMemo(
        () => ({
            search: searchTerm,
            view: view,
            ...screenerRequestParams,
        }),
        [searchTerm, screenerRequestParams],
    );

    const getScreenerDataInputProps = useMemo(
        () => ({
            ...extendedScreenerRequestParams,
            ...(useSSRMode && { size: ssrmMaxRowsToFetch, from: 0 }),
        }),
        [extendedScreenerRequestParams, useSSRMode],
    );
    const screenerData = getScreenerData?.(getScreenerDataInputProps) as UseQueryResult<ScreenerData>;

    useEffect(() => {
        if (!chipStateDispatcher) {
            return;
        }

        if (screenerData.isLoading) {
            chipStateDispatcher({ type: 'SetResultCount', newState: { resultCount: -1 } });
            return;
        }

        //check to prevent Audit trail card from re-rendering continuously when no data
        if (screenerData.data?.results && screenerData.data?.results?.data.length > 0) {
            setGridRowsData(screenerData.data?.results?.data);
        }

        chipStateDispatcher({
            type: 'SetResultCount',
            newState: {
                resultCount: screenerData.data?.results?.total || 0,
            },
        });
    }, [screenerData.isLoading, screenerData.data?.results, chipStateDispatcher]);

    const SSRDataExportFn = useMemo(
        () =>
            getSsrDataExportFn?.({
                metadataFields: screenerData?.data?._metadata.fields || [],
                requestParams: extendedScreenerRequestParams,
            }),
        [screenerData?.data?._metadata.fields, extendedScreenerRequestParams, getSsrDataExportFn],
    );

    const gridRef = useRef<AgGridReact>(null);

    const resultsRow = useMemo(
        () => (
            <ResultsAndExportRow
                cardName={cardName}
                gridRef={gridRef}
                fieldMetadata={screenerData?.data?._metadata.fields}
                {...(useSSRMode && { SSRDataExportFn })}
            />
        ),
        [cardName, SSRDataExportFn, useSSRMode, screenerData?.data?._metadata.fields],
    );

    const openModalsButtons = useMemo(() => {
        if (!modals) {
            return [];
        }

        return modals
            .filter((p) => !p.props.doNotShowModalOpenButtonOnScreenerCard)
            .filter((p) => !(enableEdit && p.props.hideOnEditing))
            .map(({ props }) => {
                return (
                    <FiltersModalOpenButton
                        cardName={cardName}
                        key={`topLeftActionPanelItems - ${props.title}`}
                        manageFiltersButtonText={props.buttonText || props.title}
                        multipleModalsContextKey={props.title}
                    />
                );
            });
    }, [cardName, modals, enableEdit]);

    const setCardDefaultValues = useCallback(() => {
        if (getDefaultView) {
            const { defaultRowsData, defaultFormData } = getDefaultView(screenerData?.data?.results.data || [], formData || {})
            setFormData(defaultFormData)
            setFormDataState(undefined);
            setRowsToBeAdded(undefined);
            setRowsToBeDeleted(undefined);
            setGridRowsData(defaultRowsData);
            chipStateDispatcher({
                type: 'SetResultCount',
                newState: {
                    resultCount: defaultRowsData?.length || 0,
                },
            });
            onCancel?.();
        }
    }, [getDefaultView, screenerData?.data?.results.data, formData, chipStateDispatcher, onCancel]);

    const onEditChange = useCallback(
        (value: boolean) => {
            setEnableEdit(value);
            
            if (!value) {
                reset(defaultValues);
                setCardDefaultValues();
            }
        },
        [setEnableEdit, reset, defaultValues, setCardDefaultValues],
    );

    useEffect(() => {
        if (enableEdit && setInitialEditStatus) {
            let data = setInitialEditStatus(gridRowsData || []);
            if (data.length > 0) setGridRowsData(data);
        }
    }, [setInitialEditStatus, gridRowsData, enableEdit]);

    const onUpdate = useCallback(async (_: any) => {
        if (!hardRefreshOnUpdate) {
            invalidateQueriesByKey('getFiltersData');
            await invalidateQueriesByKey('getScreenerData');
            setEnableEdit(false);
        } else {
            window.location.reload();
        }
    }, [hardRefreshOnUpdate]);
    
    const onDeleteButtonClicked = useCallback(async (selectedIds: string[]) => {
        processDeleteAction?.(setValue, selectedIds, rowLevelFiltersFormKeys);
        submitHandler?.();
    }, [processDeleteAction, rowLevelFiltersFormKeys, setValue, submitHandler]);

    useEffect(() => {
        if (externalFormData && processAddAction) {
            const { dataToAdd, hasUpdate } = processAddAction(gridRowsData || [], externalFormData);
            if (hasUpdate) {
                setRowsToBeAdded(dataToAdd);
            }
        }
    }, [externalFormData, gridRowsData, processAddAction])

    const topRightActionPanelItems = useMemo(
        () => [
            <SearchInput
                key='SearchInput'
                cardName={cardName}
                inputStyles={{ marginRight: '0px' }}
                placeholder={searchPlaceholder}
            />,
        ],
        [cardName, searchPlaceholder],
    );

    const isNoInformationAvailable = useMemo(
        () =>
            !screenerData.data ||
            !screenerData.data._metadata ||
            !screenerData.data._metadata.fields ||
            screenerData.data._metadata.fields.length === 0 ||
            !screenerData.data._viewdata ||
            !screenerData.data._viewdata.fields ||
            screenerData.data._viewdata.fields.length === 0 ||
            !screenerData.data.results ||
            !screenerData.data.results.data ||
            screenerData.data.results.data.length === 0,
        [screenerData.data],
    );

    const rowFiltersDataAvailable = useMemo(
        () =>
            Boolean(
                !rowLevelFiltersDataQueryResult?.isLoading &&
                    rowLevelFiltersDataQueryResult?.data &&
                    rowLevelFiltersDataQueryResult?.data.filter_metadata,
            ),
        [rowLevelFiltersDataQueryResult?.data, rowLevelFiltersDataQueryResult?.isLoading],
    );

    const confirmationModalContent = useMemo(() => {
        return getConfirmationModalContent?.(gridRowsData, rowsToBeDeleted);
    }, [getConfirmationModalContent, gridRowsData, rowsToBeDeleted])
    

    useEffect(() => {
        if (!getFormDataState || !enableEdit) return;

        const { state, deletion, addition, updatedRowsData, isRowsDataUpdated } = getFormDataState(gridRowsData, { rowsToBeAdded, externalFormData }, formData);
        
        setFormDataState(state);
       
        if (isRowsDataUpdated && updatedRowsData) {
            setGridRowsData(updatedRowsData);
        }

        if (deletion?.length) {
            setRowsToBeDeleted(deletion);
        }

        if (addition?.length && isRowsDataUpdated) {
            gridRef.current?.api?.setGridOption('rowData', updatedRowsData);
            chipStateDispatcher({
                type: 'SetResultCount',
                newState: {
                    resultCount: updatedRowsData?.length || 0,
                },
            });
        }
    }, [gridRowsData, rowsToBeAdded, formData, externalFormData, enableEdit, getFormDataState, chipStateDispatcher])


    const modifyDeleteRequestFn = useMemo(
        () => operationType && entityType && getModifyDeleteRequestFunction(entityType, operationType, screenerData),
        [operationType, entityType, screenerData],
    );

    const modifyRequestBody = useMemo(() => {
        if (rowLevelFiltersDataQueryResult?.data && entityType && operationType && entityId) {
            return (requestBody: Array<Record<string, any>> | Record<string, any>) => modifyRequestBodyFromFilterMetadata({
                requestBody,
                filtersData: rowLevelFiltersDataQueryResult?.data,
                entityType,
                operationType,
                entityId
            })
        }
        else {
            return undefined;
        }
    }, [rowLevelFiltersDataQueryResult?.data, entityType, operationType, entityId]);

    const topLeftActionPanelItems = useMemo(() => {
        const result: Array<React.ReactNode> = [...openModalsButtons];
        if (rowFiltersDataAvailable && rowLevelFiltersDataQueryResult?.data) {
            result.push(
                <SnackbarProvider key='EditButtonsBlock'>
                    <EditButtonsVariant
                        formState={formState}
                        onEditChange={onEditChange}
                        editButtonText={`Edit ${cardName}`}
                        analyticsCardName={cardName}
                        requestPath={screenerRequestParams.overrideRequestPath || screenerRequestParams.path + '/_batch'}
                        formData={formData}
                        edit={enableEdit}
                        filtersData={rowLevelFiltersDataQueryResult.data}
                        onUpdate={onUpdate}
                        sectionKey={rowLevelFiltersConfig?.requestParams?.type}
                        requestDefaultValue={rowLevelFiltersConfig?.requestDefaultValue}
                        cardName={cardName}
                        onDeleteClicked={onDeleteButtonClicked}
                        confirmationModalContent={confirmationModalContent}
                        formDataState={formDataState}
                        operationType={operationType}
                        modifyDeleteRequestFn={modifyDeleteRequestFn}
                        modifyRequestBody={modifyRequestBody}
                    />
                </SnackbarProvider>,
            );
        }

        return result;
    }, [
        cardName,
        onUpdate,
        rowFiltersDataAvailable,
        rowLevelFiltersDataQueryResult?.data,
        formData,
        enableEdit,
        formState,
        onEditChange,
        rowLevelFiltersConfig?.requestParams?.type,
        rowLevelFiltersConfig?.requestDefaultValue,
        screenerRequestParams.path,
        openModalsButtons,
        confirmationModalContent,
        formDataState,
        operationType,
        modifyDeleteRequestFn,
        EditButtonsVariant,
        modifyRequestBody,
        onDeleteButtonClicked,
        screenerRequestParams.overrideRequestPath
    ]);

    // set up default values for row level filters
    useEffect(() => {
        if (!rowFiltersDataAvailable || isNoInformationAvailable || !screenerData.data) {
            return;
        }

        const formElementNameToDefaultValue: Record<string, any> = {};
        const filterFormKeys: Record<string, Record<string, string>> = {};

        screenerData.data._viewdata.fields.forEach((viewdataFieldObject) => {
            const columnMetadataKey = Object.keys(viewdataFieldObject)[0];
            const viewdataObject = viewdataFieldObject[columnMetadataKey];
            const cellRendererParams = viewdataObject.cell_renderer_params;
            if (!cellRendererParams) {
                return;
            }

            const cellRendererParamWithFilter = getObjectWithFilter(cellRendererParams);

            if (!cellRendererParamWithFilter) {
                return;
            }

            const filterMetadataKey = cellRendererParamWithFilter['filter'];

            if (!filterMetadataKey) {
                throw new Error(
                    `unexpected filter value for cell_renderer_params in ${columnMetadataKey} - ${filterMetadataKey}`,
                );
            }

            if (!rowLevelFiltersDataQueryResult?.data?.filter_metadata[filterMetadataKey]) {
                throw new Error(
                    `unable to set up default values for row level filters. filter_metadata not found for ${filterMetadataKey}`,
                );
            }

            const filterMetadata = rowLevelFiltersDataQueryResult?.data?.filter_metadata[filterMetadataKey];
            const componentName = filterMetadata.component;

            if (!componentName) {
                return;
            }
            
            gridRowsData?.forEach((rowData) => {
                let defaultValue = rowData[columnMetadataKey];

                if (!defaultValue) {
                    defaultValue = applyCustomFilterValueFormatter({
                        rowData: rowData,
                        viewdata: screenerData.data._viewdata,
                        metadata: screenerData.data._metadata,
                        fieldMetadata: filterMetadata.item_metadata,
                        returnRawCalculatedValue: true,
                    });
                }

                const formElementName = combineIntoFormElementName({
                    componentName,
                    filterMetadataKey,
                    uniqueIdWithinGroup: rowData.id,
                });
                formElementNameToDefaultValue[formElementName] = defaultValue;
                filterFormKeys[rowData.id] = { [filterMetadataKey]: formElementName };
            });
        });
        
        setRowLevelFiltersFormKeys(filterFormKeys);

        if (Object.keys(formElementNameToDefaultValue).length === 0) {
            return;
        }

        return setDefaultValues?.(formElementNameToDefaultValue);
    }, [
        isNoInformationAvailable,
        screenerData.data,
        gridRowsData,
        setDefaultValues,
        rowFiltersDataAvailable,
        rowLevelFiltersDataQueryResult?.data?.filter_metadata
    ]);

    const filterKeysToRowLevelFilter = useMemo(() => {
        if (!rowFiltersDataAvailable) {
            return;
        }

        if (!rowLevelFiltersConfig?.requestParams?.type) {
            throw new Error('rowLevelFiltersRequestParams.type is required for row level filters');
        }

        if (!rowLevelFiltersDataQueryResult?.data) {
            throw new Error('rowLevelFiltersDataQueryResult.data is required for row level filters');
        }

        return getFilterKeysToRowLevelFilter({
            parentSectionKey: rowLevelFiltersConfig?.requestParams?.type,
            rowLevelFiltersData: rowLevelFiltersDataQueryResult.data,
            getValues: getValues,
            setValue: setValue,
            trigger: trigger,
            control: control,
            enableEdit,
            submitHandler,
        });
    }, [
        rowFiltersDataAvailable,
        rowLevelFiltersConfig?.requestParams?.type,
        rowLevelFiltersDataQueryResult?.data,
        getValues,
        setValue,
        trigger,
        control,
        enableEdit,
        submitHandler,
    ]);

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        const defaultValues: any = { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };

        if ((isNoInformationAvailable || !screenerData.data) && !enableEdit) {
            return defaultValues;
        }

        if (!screenerData.data) {
            return defaultValues;
        }

        const result = extractFromScreenerData({
            screenerData: screenerData.data,
            cardName,
            filterKeysToRowLevelFilter
        });
        if (enableEdit && showSelectionOnEdit) {
            result.columnDefs.unshift({
                sortable: false,
                checkboxSelection: true,
                onCellClicked(event: any) {
                    let node = event.node;

                    let isSelected = node.isSelected() === undefined ? false : node.isSelected();
                    node.setSelected(!isSelected);
                }
            });
        }

        return result;
    }, [isNoInformationAvailable, screenerData.data, enableEdit, cardName, filterKeysToRowLevelFilter, showSelectionOnEdit]);

    useEffect(() => {
        if (enableEdit && getColumnOrderderedInEditMode) {
            setGridColumnDef(getColumnOrderderedInEditMode(columnDefs))
        } else {
            setGridColumnDef(columnDefs);
        }
    }, [columnDefs, enableEdit, getColumnOrderderedInEditMode]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) => minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const dataSource = useMemo(
        () =>
            getDataSource?.({
                metadataFields: screenerData?.data?._metadata.fields || [],
                etfData: screenerData?.data?.results.data || [],
                requestParams: extendedScreenerRequestParams,
            }),
        [
            extendedScreenerRequestParams,
            screenerData?.data?._metadata.fields,
            screenerData?.data?.results.data,
            getDataSource,
        ],
    );

    const getRowHeight = useMemo(() => getRowHeightBasedOnListData(screenerData.data), [screenerData.data]);

    const rowClassRules = useMemo(() => {
        return {
            // row style function
            [AgGridRowClassRules.ToBeDeleted]: (param: any) => {
                if (getRowClassRules) {
                    return getRowClassRules(AgGridRowClassRules.ToBeDeleted, rowsToBeDeleted || [], param?.data)
                } else {
                    return false;
                }
            },
            [AgGridRowClassRules.ToBeAdded]: (param: any) => {
                if (getRowClassRules) {
                    return getRowClassRules(AgGridRowClassRules.ToBeAdded, [], param?.data)
                } else {
                    return false;
                }
            }
        };
    }, [getRowClassRules, rowsToBeDeleted]);

    const resultChild = useMemo(() => {
        return (
            <AgGridSelectedRowsContextProvider isSSRMEnabled={useSSRMode}>
                <AgGirdCard
                    label={cardName}
                    labelProps={{ width: '100%' }}
                    showDefaultExportButton={false}
                    ref={gridRef}
                    columnDefs={gridColumnDef || columnDefs}
                    rowMultiSelectWithClick={true}
                    suppressRowClickSelection={true}
                    customFlexibleColumns={customFlexibleColumns}
                    getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                    {...(enableTopLeftActionPanel ? { topLeftActionPanelItems, topLeftActionPanelStyles } : {})}
                    {...(enableTopRightActionPanel ? { topRightActionPanelItems, topRightActionPanelStyles } : {})}
                    topPanelSlot3Content={resultsRow}
                    labelPanelContainerStyles={{ paddingTop: '36px' }}
                    getRowHeight={getRowHeight}
                    {...(useSSRMode
                        ? { useSSRMode, getRowID, SSRrowsToFetch: ssrmMaxRowsToFetch, SSRDataSource: dataSource }
                        : { rowsData: gridRowsData, getRowID })}
                    maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
                    unlimitedCalculatedHeight={unlimitedCalculatedHeight}
                    rowClassRules={rowClassRules}
                />
            </AgGridSelectedRowsContextProvider>
        );
    }, [
        cardName,
        columnDefs,
        customFlexibleColumns,
        dataSource,
        enableTopLeftActionPanel,
        enableTopRightActionPanel,
        getResizableMinWidthForColumn,
        getRowHeight,
        gridRef,
        resultsRow,
        useSSRMode,
        gridRowsData,
        topLeftActionPanelItems,
        topRightActionPanelItems,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
        gridColumnDef,
        rowClassRules
    ]);

    return resultChild;
}
