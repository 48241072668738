import {
    applyCustomFilterValueFormatter,
    getFieldMetaBySourceField,
    getValueByFieldKey,
    updateMetadataUrlLinkParams,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import {
    Components,
    FilterMetadata,
    FiltersData,
    FiltersMetadata,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { CreateModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import { InformationPopup } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/InformationPopup';
import { ScreenerHomeProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { SendAlertWrapper } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/SendAlertWrapper';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { DataItem, ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    OperationTypes,
    RequestTypes,
    UserPlatformManagementEntityTypes,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import _ from 'lodash';
import React from 'react';
import { processAuditTrailRecords } from './AuditTrailUtils';
import { getEditButtonVariant2 } from '../EditButtonsV2';
import { UseFormSetValue, FieldValues } from 'react-hook-form';
import { AgGridRowClassRules } from '../../utils/enum';
import { Box, Chip } from '@mui/material';
import { StyledFormLabel } from '../../../Form/shared/StyledFormLabel';
import { AddForm } from '../../forms/AddForm';
import { getDividerString } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { ColumnDef } from '../../../AgGrid/types';
import { CreateFormProps } from '../../forms/CreateForm';
import { getDateTimeUtcNow } from '@cfra-nextgen-frontend/shared/src/utils';

function _getFiltersMetadataByViewType(
    screenerData: ScreenerData,
    originalFiltersMetadata: FiltersMetadata,
    isStatic: boolean,
) {
    const resultFiltersMetadata = _.cloneDeep(originalFiltersMetadata);
    const defaultValues: Record<string, any> = {};

    if (isStatic) {
        Object.keys(resultFiltersMetadata).forEach((key) => {
            resultFiltersMetadata[key].component = Components.StaticText;
        });
    }

    for (const key in resultFiltersMetadata) {
        const { item_metadata, source_field } = resultFiltersMetadata[key];

        //update url_link params
        if (item_metadata && item_metadata?.['url_link']?.['params_source_field']) {
            resultFiltersMetadata[key]['item_metadata'] = updateMetadataUrlLinkParams(item_metadata, {
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                metadata: screenerData._metadata,
            });
        }

        //fetch or derive default value
        if (item_metadata && item_metadata?.source_fields && item_metadata?.value_formatter) {
            resultFiltersMetadata[key]['default_value'] = applyCustomFilterValueFormatter({
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                metadata: screenerData._metadata,
                fieldMetadata: resultFiltersMetadata[key].item_metadata,
                returnRawCalculatedValue: isStatic,
            });
        } else if (typeof source_field === 'string') {
            const fieldMetaList = getFieldMetaBySourceField(screenerData._metadata, source_field);

            if (fieldMetaList.length === 0) {
                throw new Error(`Field meta not found for source_field: ${source_field}`);
            }

            const fieldPath = Object.keys(fieldMetaList[0])[0];
            const defaultValue = getValueByFieldKey({
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                fieldPath,
            });
            resultFiltersMetadata[key]['default_value'] = defaultValue;

            let defaultValues: any[] = [];
            for (let i = 0; i < screenerData.results.data.length; i++) {
                const val = getValueByFieldKey({
                    rowData: screenerData.results.data[i],
                    viewdata: screenerData._viewdata,
                    fieldPath,
                });
                if (val !== undefined || val !== null) {
                    if (Array.isArray(val)) {
                        defaultValues = defaultValues.concat(val);
                    } else {
                        defaultValues.push(val);
                    }
                }
            }

            resultFiltersMetadata[key]['default_values'] = defaultValues.length > 0 ? defaultValues : null;
        }

        const formKey = combineIntoFormElementName({
            componentName: isStatic ? Components.StaticText : resultFiltersMetadata[key].component,
            filterMetadataKey: key,
        });

        defaultValues[formKey] = resultFiltersMetadata[key]['default_value'];
    }

    return { resultFiltersMetadata, defaultValues };
}

export function getFiltersMetadataByViewType({
    screenerData,
    filtersMetadata,
}: {
    screenerData: ScreenerData;
    filtersMetadata: FiltersMetadata;
}) {
    const { resultFiltersMetadata: nonStaticFiltersMetadata, defaultValues: nonStaticDefaultValues } =
        _getFiltersMetadataByViewType(screenerData, filtersMetadata, false);
    const { resultFiltersMetadata: staticFiltersMetadata, defaultValues: staticDefaultValues } =
        _getFiltersMetadataByViewType(screenerData, filtersMetadata, true);

    return {
        filtersMetadata: nonStaticFiltersMetadata,
        defaultValues: nonStaticDefaultValues,
        staticFiltersMetadata,
        staticDefaultValues,
    };
}

export type SingleModalProps = {
    onOperationSuccessSnackMessage: string;
    entityType: UserPlatformManagementEntityTypes;
    submitButtonName: string;
    title: string;
    buttonText?: string;
    operationType?: OperationTypes;
    requestPath?: string;
    description?: ModalProps['description'];
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filtersData?: FiltersData) => void;
    multipleResultsPopupId?: string;
    multipleResultsPopup?: React.ReactNode;
    multipleResultsPopupTitle?: string;
    maxNumberOfItemsPerOneRequest?: number;
    presetValues?: Record<string, any>;
    localFiltersMetadata?: Record<string, Partial<FilterMetadata>>;
    doNotShowModalOpenButtonOnScreenerCard?: boolean;
    useScreenerDataForFilters?: boolean;
    FormComponent?: React.FC<CreateFormProps>;
    hideOnEditing?: Boolean;
};

export type RowLevelFiltersConfig = {
    requestParams?: SearchByParams;
    requestDefaultValue?: Record<string, any>;
};

export type ModalWrapperProps = { ModalComponent: React.FC<CreateModalProps> } & CreateModalProps;

export type Modal = {
    props: SingleModalProps;
    ModalWrapper?: React.FC<ModalWrapperProps>;
};

export type EntityAssociatedItem = {
    screenerProps: Partial<ScreenerHomeProps>;
    modals?: Array<Modal>;
};

function BulkUploadDescription() {
    const file = 'Bulk_Upload_Template.xlsx';
    const templateLink = (
        <a href={`/files/${file}`} download={file}>
            template.xlsx
        </a>
    );

    return (
        <>
            Input list of users for bulk upload into the {templateLink}. All fields for a user must be populated in
            order for user upload.
        </>
    );
}

export function getEntityAssociatedItems(
    entityType: UserPlatformManagementEntityTypes,
    entityId: number,
): Array<EntityAssociatedItem> {
    if (entityType === UserPlatformManagementEntityTypes.Account) {        
        return [
            {
                screenerProps: {
                    cardName: 'Groups',
                    screenerRequestParams: {
                        path: 'group',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create Group',
                            submitButtonName: 'Create Group',
                            onOperationSuccessSnackMessage: 'Successfully created group {name}',
                            entityType: UserPlatformManagementEntityTypes.Group,
                            onBeforeCreateSuccess: () => { },
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.group.company_name',
                                })]: '{account_name}',
                                [combineIntoFormElementName({
                                    componentName: Components.Checkbox,
                                    filterMetadataKey: 'user_management.group.is_active',
                                })]: { value: true, isDirty: true }
                            },
                        },
                    },
                    {
                        props: {
                            title: 'Edit Group',
                            submitButtonName: 'Edit Group',
                            onOperationSuccessSnackMessage: 'Successfully updated group {name}',
                            entityType: UserPlatformManagementEntityTypes.Group,
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for User',
                    screenerRequestParams: {
                        path: 'user',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create User',
                            submitButtonName: 'Create User',
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                    {
                        props: {
                            title: 'Bulk User Create for {profileTitle}',
                            buttonText: 'Bulk User Create',
                            submitButtonName: 'Create',
                            onOperationSuccessSnackMessage: 'Successfully created {count} user{countBasedEnding}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            operationType: OperationTypes.BULK_CREATE,
                            requestPath: 'user/_batch',
                            description: <BulkUploadDescription />,
                            multipleResultsPopupId: 'Bulk User Create Failed Results Popup',
                            multipleResultsPopup: (
                                <InformationPopup
                                    modalId={'Bulk User Create Failed Results Popup'}
                                    analyticsCardName={'Bulk User Create Failed Results Popup'}
                                />
                            ),
                            multipleResultsPopupTitle:
                                'The following {numberOfItemsFailedToOperate} users were not created:',
                            maxNumberOfItemsPerOneRequest: 20,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Account Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['account'] },
                                    'user_management.audit.op': { values: ['UPDATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            }
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.User) {
        return [
            {
                screenerProps: {
                    cardName: 'Groups',
                    enableTopLeftActionPanel: true,
                    screenerRequestParams: {
                        path: 'user_group',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_group.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user_group',
                            type: 'edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Add/Remove Groups',
                            submitButtonName: 'Save',
                            entityType: UserPlatformManagementEntityTypes.User_Group,
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            onBeforeCreateSuccess: () => { },
                            operationType: OperationTypes.BULK_EDIT,
                            useScreenerDataForFilters: true
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_group.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Notifications',
                    enableTopLeftActionPanel: true,
                    screenerRequestParams: {
                        path: 'user_alert_subscription',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_alert_subscription.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user_alert_subscription',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Add Notifications',
                            submitButtonName: 'Save',
                            entityType: UserPlatformManagementEntityTypes.User_Alert_Subscription,
                            onOperationSuccessSnackMessage: 'Successfully created notification',
                            onBeforeCreateSuccess: () => {},
                            requestPath: 'user_alert_subscription',
                            operationType: OperationTypes.CREATE,
                            useScreenerDataForFilters: true
                        },
                    },
                ]
            },
            {
                screenerProps: {
                    cardName: 'User Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'user_group',
                        requestBody: {
                            filters: {
                                values: {
                                    $or: [
                                        {
                                            $and: [
                                                {
                                                    $or: [
                                                        {
                                                            'user_management.audit.record->>user_id': {
                                                                values: [entityId],
                                                            },
                                                        },
                                                        {
                                                            'user_management.audit.old_record->>user_id': {
                                                                values: [entityId],
                                                            },
                                                        },
                                                    ],
                                                },
                                                {
                                                    'user_management.audit.table_name': {
                                                        values: ['user_group'],
                                                    },
                                                },
                                                {
                                                    'user_management.audit.op': {
                                                        values: ['CREATE', 'UPDATE', 'DELETE'],
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            'user_management.audit.table_name': {
                                                values: ['user'],
                                            },
                                            'user_management.audit.op': {
                                                values: ['UPDATE'],
                                            },
                                            'user_management.audit.record->>id': {
                                                values: [entityId],
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.Group) {
        return [
            {
                screenerProps: {
                    cardName: 'Packages',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    entityType: UserPlatformManagementEntityTypes.Group_Package,
                    searchPlaceholder: 'Search for Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'account_group',
                        overrideRequestPath:'group_package',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'group_package',
                            type: OperationTypes.BULK_EDIT
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                    EditButtonsVariant: getEditButtonVariant2({ addButtontitle: 'Add Packages' }),
                    showSelectionOnEdit: true,
                    hardRefreshOnUpdate: true,
                    operationType: OperationTypes.BULK_EDIT,
                    processDeleteAction: (
                        setValue: UseFormSetValue<FieldValues>,
                        selectedIds: string[],
                        rowLevelFiltersFormKeys?: Record<string, Record<string, string>>,
                    ) => {
                        selectedIds.forEach((id) => {
                            const formFilterName = rowLevelFiltersFormKeys?.[id]?.['user_management.package.is_active'];
                            if (formFilterName) {
                                setValue(formFilterName, false, { shouldDirty: true });
                            }
                        });
                    },
                    getRowClassRules: (ruleType: AgGridRowClassRules, selectedIds: string[], rowData: any) => {
                        switch (ruleType) {
                            case AgGridRowClassRules.ToBeDeleted:
                                return selectedIds.indexOf(rowData.id) > -1;
                            case AgGridRowClassRules.ToBeAdded:
                                return rowData.newRow;
                            default:
                                return false;
                        }
                    },
                    getConfirmationModalContent: (rowsData?: DataItem[], rowsToBeDeleted?: string[]) => {
                        const data = [
                            {
                                title: 'Added Packages',
                                data: (rowsData || [])
                                    .filter((p) => p.newRow)
                                    .map((p) => <Chip sx={{ margin: '5px' }} key={p.id} label={p.package?.name} />),
                            },
                            {
                                title: 'Removed Packages',
                                data: (rowsToBeDeleted || []).map((id) => {
                                    const rowData = rowsData?.find((p) => p.id === id);
                                    return (
                                        <Chip sx={{ margin: '5px' }} key={rowData?.id} label={rowData?.package.name} />
                                    );
                                }),
                            },
                        ];
                        return (
                            <Box sx={{ width: '100%', paddingTop: '30px' }}>
                                {data.map((item: any) => (
                                    <div key={item.title}>
                                        <StyledFormLabel
                                            sx={{ lineHeight: 1.2, paddingTop: '10px', paddingBottom: '10px' }}>
                                            {item.title}
                                        </StyledFormLabel>

                                        {item.data.length > 0 ? item.data : <div style={{ marginLeft: '5px' }}>-</div>}
                                    </div>
                                ))}
                            </Box>
                        );
                    },
                    getFormDataState: (
                        rowsData?: DataItem[],
                        additonData?: {
                            rowsToBeAdded?: any[];
                            externalFormData?: {
                                filtersData: FiltersData;
                                formData: RhFormData;
                                formDataState: Record<string, any>;
                            };
                        },
                        formData?: RhFormData,
                    ) => {
                        let idsToDelete: any[] = [];

                        if (formData) {
                            idsToDelete = Object.keys(formData)
                                .filter((formKey) => formData[formKey] === false)
                                .map((formKey) => {
                                    const keys = formKey?.split(getDividerString('DIVIDER'));
                                    return Number.parseInt(keys[keys.length - 1]);
                                });
                        }

                        let updatedRowsData = [...(rowsData || [])];
                        let isRowsDataUpdated =
                            updatedRowsData
                                .filter((row: any) => idsToDelete.includes(row.id) && row.package.is_active)
                                .map((row: any) => {
                                    row.package.is_active = false;
                                    return '';
                                }).length > 0;

                        if (additonData?.rowsToBeAdded?.length) {
                            updatedRowsData = updatedRowsData.filter((r) => !r.newRow);
                            let id: number = Math.max(...(rowsData?.map((p) => Number.parseInt(p.id)) || [9999]));
                            const packagesData: Array<any> =
                                additonData?.externalFormData?.filtersData?.data[
                                    'user_management.package.group_package'
                                ].items || [];

                            const productsData: Array<any> =
                                additonData?.externalFormData?.filtersData?.data[
                                    'user_management.lookup_product.lookup_id'
                                ].items || [];

                            additonData.rowsToBeAdded.map((row) => {
                                let selectedPackage = packagesData.find((item) => item.key === row.key);
                                let selectedProduct = selectedPackage.product_lid
                                    ? productsData.find((item) => item.key === selectedPackage.product_lid)
                                    : undefined;

                                updatedRowsData.push({
                                    id,
                                    package: {
                                        id: row.key,
                                        description: row.description,
                                        name: selectedPackage.value,
                                        is_active: selectedPackage.is_active,
                                        lookup_product: {
                                            value: selectedProduct.value,
                                            lookup_id: selectedProduct.key,
                                        },
                                    },
                                    group: {
                                        id: entityId,
                                    },
                                    newRow: true,
                                });
                                id++;
                                return '';
                            });

                            const newRowsKeys = rowsData
                                ?.filter((rowData) => rowData.newRow)
                                .map((rowData) => rowData.package.id);

                            const keysToAdd = additonData?.rowsToBeAdded?.map((p) => p.key);

                            isRowsDataUpdated = newRowsKeys?.length
                                ? newRowsKeys.length !== keysToAdd.length ||
                                  newRowsKeys.some((key) => !keysToAdd.includes(key))
                                : true;
                        }

                        const formKey = combineIntoFormElementName({
                            componentName: Components.AutocompleteOutsideChips,
                            filterMetadataKey: 'user_management.package.group_package',
                        });

                        return {
                            state: {
                                [formKey]: {
                                    [RequestTypes.DELETE]: idsToDelete.map((p) => ({ key: p })),
                                    [RequestTypes.POST]: updatedRowsData
                                        .filter((p) => p.newRow)
                                        .map((p) => ({ key: p.package.id, description: p.package.description })),
                                },
                            },
                            deletion: idsToDelete,
                            addition: additonData?.rowsToBeAdded,
                            isRowsDataUpdated,
                            updatedRowsData: updatedRowsData,
                        };
                    },
                    processAddAction: (rowsData, externalFormData) => {
                        let dataToAdd: Array<any> = [];

                        const newRowsKeys = rowsData
                            .filter((rowData) => rowData.newRow)
                            .map((rowData) => rowData.package.id);

                        if (externalFormData.formDataState) {
                            const dataState = externalFormData.formDataState;
                            const filterData =
                                externalFormData.filtersData.data['user_management.package.group_package'].items;

                            dataToAdd = Object.keys(dataState)
                                .filter((key) => dataState[key]?.POST !== undefined)
                                .flatMap((key) => dataState[key].POST, Infinity)
                                .map((postItem) => {
                                    return {
                                        key: postItem.key,
                                        description: filterData.find((filter) => filter.key === postItem.key)?.value,
                                    };
                                });
                        }
                        
                        const keysToAdd = dataToAdd.map((item) => item.key);
                        return {
                            dataToAdd,
                            hasUpdate: newRowsKeys.length
                                ? newRowsKeys.length !== dataToAdd.length ||
                                  newRowsKeys.some((key) => !keysToAdd.includes(key))
                                : true,
                        };
                    },
                    getDefaultView: (rowsData: DataItem[], formData: RhFormData) => {                       
                        Object.keys(formData).forEach((key) => (formData[key] = true));
                        //Editing is now done, we move back the original value of package enabled and deleting our temp placehold field for it on each row
                        return {
                            defaultRowsData: rowsData
                                .filter((p) => !p.newRow)
                                .map((p) => {
                                    p.package.is_active = p.package_status;
                                    delete p.package_status;
                                    return p;
                                }),
                            defaultFormData: formData,
                        };
                    },
                    getColumnOrderderedInEditMode: (columnDefs: ColumnDef[]) => {
                        //package enabled column is used as a template for "Edit Status", and the original column is being removed from the display while "edit" mode is on.
                        columnDefs.splice(1, 0, {
                            ...columnDefs[columnDefs.length - 1],
                            headerName: 'Edit Status',
                        });
                        return columnDefs.slice(0, -1);
                    },
                    setInitialEditStatus: (rowsData: DataItem[]) => {
                        //packages might be disabled, so when moving the column to be used for "edit status", we have to make sure all column values are true.
                        //the original value package enabled is moved to a  temp field and then it is marked as true so that all rows are ready for editing
                        //expected to be done only once when editing starts
                        if (!rowsData.every((p) => 'package_status' in p)) {
                            return rowsData.map((p: any) => {
                                p.package_status = p.package.is_active;
                                p.package.is_active = true;
                                return p;
                            });
                        }
                        else return [];
                    }
                },
                modals: [
                    {
                        props: {
                            title: 'Add Packages',
                            submitButtonName: 'Add',
                            entityType: UserPlatformManagementEntityTypes.Group_Package,
                            onOperationSuccessSnackMessage: '',
                            onBeforeCreateSuccess: () => {},
                            operationType: OperationTypes.BULK_CREATE,
                            doNotShowModalOpenButtonOnScreenerCard: true,
                            useScreenerDataForFilters: true,
                            FormComponent: AddForm,
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for User',
                    entityType: UserPlatformManagementEntityTypes.Group_User,
                    screenerRequestParams: {
                        path: 'user',
                        view: 'account_profile',
                        overrideRequestPath: 'user_group',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                    EditButtonsVariant: getEditButtonVariant2({
                        addButtontitle: 'Add Users',
                        defaultConfirmTitle: 'Confirm Changes',
                        buttonCaptions: ['Add', 'Deactivate'],
                        //position: 'left',
                    }),
                    operationType: OperationTypes.BULK_EDIT,
                    hardRefreshOnUpdate: true,
                    showSelectionOnEdit: true,
                    processDeleteAction: (
                        setValue: UseFormSetValue<FieldValues>,
                        selectedIds: string[],
                        rowLevelFiltersFormKeys?: Record<string, Record<string, string>>,
                    ) => {
                        selectedIds.forEach((id) => {
                            const formFilterName = rowLevelFiltersFormKeys?.[id]?.['user_management.user.is_active'];
                            if (formFilterName) setValue(formFilterName, false, { shouldDirty: true });
                        });
                    },
                    getRowClassRules: (ruleType: AgGridRowClassRules, selectedIds: string[], rowData: any) => {
                        switch (ruleType) {
                            case AgGridRowClassRules.ToBeDeleted:
                                return selectedIds.indexOf(rowData.id) > -1;
                            case AgGridRowClassRules.ToBeAdded:
                                return rowData.newRow;
                            default:
                                return false;
                        }
                    },
                    getConfirmationModalContent: (rowsData?: DataItem[], rowsToBeDeleted?: string[]) => {
                        const data = [
                            {
                                title: 'Added Users',
                                data: (rowsData || [])
                                    .filter((p) => p.newRow)
                                    .map((p) => (
                                        <Chip
                                            sx={{ margin: '5px' }}
                                            key={p.id}
                                            label={p.first_name + ' ' + p.last_name}
                                        />
                                    )),
                            },
                            {
                                title: 'Removed Users',
                                data: (rowsToBeDeleted || []).map((id) => {
                                    const rowData = rowsData?.find((p) => p.id === id);
                                    return (
                                        <Chip
                                            sx={{ margin: '5px' }}
                                            key={rowData?.id}
                                            label={rowData?.first_name + ' ' + rowData?.last_name}
                                        />
                                    );
                                }),
                            },
                        ];

                        return (
                            <Box sx={{ width: '100%', paddingTop: '30px' }}>
                                {data.map((item: any) => (
                                    <div key={item.title}>
                                        <StyledFormLabel
                                            sx={{
                                                lineHeight: 1.2,
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            }}>
                                            {item.title}
                                        </StyledFormLabel>

                                        {item.data.length > 0 ? item.data : <div style={{ marginLeft: '5px' }}>-</div>}
                                    </div>
                                ))}
                            </Box>
                        );
                    },
                    getFormDataState: (
                        rowsData?: DataItem[],
                        additonData?: {
                            rowsToBeAdded?: any[];
                            externalFormData?: {
                                filtersData: FiltersData;
                                formData: RhFormData;
                                formDataState: Record<string, any>;
                            };
                        },
                        formData?: RhFormData,
                    ) => {
                        let idsToDelete: any[] = [];

                        if (formData) {
                            idsToDelete = Object.keys(formData)
                                .filter((formKey) => formData[formKey] === false)
                                .map((formKey) => {
                                    const keys = formKey?.split(getDividerString('DIVIDER'));
                                    return Number.parseInt(keys[keys.length - 1]);
                                });
                        }

                        let updatedRowsData = [...(rowsData || [])];
                        let isRowsDataUpdated =
                            updatedRowsData
                                .filter((row: any) => idsToDelete.includes(row.id) && row.status)
                                .map((row: any) => {
                                    row.status = false;
                                    row.disable_date = getDateTimeUtcNow();
                                    return '';
                                }).length > 0;

                        if (additonData?.rowsToBeAdded?.length) {
                            updatedRowsData = updatedRowsData.filter((r) => !r.newRow); // first only gets which is not newly added before.
                            const lastRow = rowsData?.sort((p) => p.id)[rowsData?.length - 1];
                            let id: number = lastRow?.id || 9999;
                           
                            const userData =
                                additonData?.externalFormData?.filtersData?.data['user_management.group.user_id'].items;
                           
                            const groupEndDateFormKey = combineIntoFormElementName({
                                    componentName: Components.DatePicker,
                                    filterMetadataKey: 'user_management.user_group.group_end_date',
                            });
        
                           
                            additonData.rowsToBeAdded.forEach((row) => {
                                // const userGroupData: any = additonData?.externalFormData?.filtersData?.data["user_management.group.user_group"].items.find(
                                //     (item) => item.key === row.key
                                // );
                                const user = userData?.find((item) => item.key === row.key) as any;

                                updatedRowsData.push({
                                    id,
                                    user_id: row.key,
                                    first_name: user?.first_name,
                                    last_name: user?.last_name,
                                    email: user?.value,
                                    enable_date: user?.enable_date,
                                    disable_date: user?.disable_date,
                                    group_end_date:  additonData?.externalFormData?.formData[groupEndDateFormKey],
                                    status: true,
                                    newRow: true, // keep this flag to use at other places
                                });
                                id++;
                            });

                            const existingDataKeys = rowsData
                                ?.filter((rowData) => rowData.newRow)
                                .map((r) => r.user_id);
                            isRowsDataUpdated = existingDataKeys?.length
                                ? existingDataKeys.length !== additonData.rowsToBeAdded.length ||
                                  existingDataKeys.some(
                                      (key) => !additonData.rowsToBeAdded?.map((p) => p.key).includes(key),
                                  )
                                : true;
                        }
                        const formKey = combineIntoFormElementName({
                            componentName: Components.Autocomplete,
                            filterMetadataKey: 'user_management.group.user_id',
                        });

                        return {
                            state: {
                                [formKey]: {
                                    [RequestTypes.DELETE]: idsToDelete.map((p) => ({ key: p })),
                                    [RequestTypes.POST]: updatedRowsData
                                        .filter((p) => p.newRow)
                                        .map((p) => ({ key: p.user_id, email: p.email, group_end_date:p.group_end_date })),
                                },
                            },
                            deletion: idsToDelete,
                            addition: additonData?.rowsToBeAdded,
                            isRowsDataUpdated,
                            updatedRowsData: updatedRowsData,
                        };
                    },
                    processAddAction: (rowsData, externalFormData) => {
                        let dataToAdd: Array<any> = [];

                        const newRowsKeys = rowsData.filter((rowData) => rowData.newRow).map((rowData) => rowData.id);

                        if (externalFormData.formDataState) {
                            const dataState = externalFormData.formDataState;
                            // const filterData = externalFormData.filtersData.data['user_management.group.user_id']
                            //     .items as Array<any>;

                            dataToAdd = Object.keys(dataState)
                                .filter((key) => dataState[key]?.POST !== undefined)
                                .flatMap((key) => dataState[key].POST, Infinity)
                                .map((postItem) => {
                                    //let item = filterData.find((filter) => filter.key === postItem.key);
                                    return {
                                        key: postItem.key,
                                        // first_name: item?.first_name,
                                        // last_name: item?.last_name,
                                        // email: item?.value
                                    };
                                });
                        }

                        const keysToAdd = dataToAdd.map((item) => item.key);

                        return {
                            dataToAdd,
                            hasUpdate: newRowsKeys.length
                                ? newRowsKeys.length !== dataToAdd.length ||
                                  newRowsKeys.some((key) => !keysToAdd.includes(key))
                                : true,
                        };
                    },
                    getDefaultView: (rowsData: DataItem[], formData: RhFormData) => {
                        Object.keys(formData).forEach((key) => (formData[key] = true));
                        return {
                            defaultRowsData: rowsData
                                .filter((p) => !p.newRow)
                                .map((p) => {
                                    p.disable_date = p.temp_disable_date;
                                    delete p.status;
                                    delete p.temp_disable_date;
                                    return p;
                                }),
                            defaultFormData: formData,
                        };
                    },
                    getColumnOrderderedInEditMode: (columnDefs: ColumnDef[]) => {
                        const indexValue = columnDefs.findIndex((columndef) => columndef.headerName === 'Enabled');
                        if (indexValue > -1) {
                            columnDefs.splice(1, 0, {
                                ...columnDefs[indexValue],
                                headerName: 'Edit Status',
                            });
                            columnDefs.splice(indexValue + 1, 1);
                        }
                        return columnDefs;
                    },
                    setInitialEditStatus: (rowsData: DataItem[]) => {
                        // adding a 'status' column to the data since "Enabled" column is dynamically created and doesn't exist in data [used to see which rows have been updated]
                        // adding a new 'temp_disable_date' column to hold the actual disable date since to deactive the user, we have to set the disable date.
                        if (!rowsData.every((p) => 'status' in p)) {
                            return rowsData.map((p: any) => {
                                p.status = true;
                                p.temp_disable_date = p.disable_date;
                                if (p.disable_date <= getDateTimeUtcNow()) {
                                    p.disable_date = null;
                                }

                                return p;
                            });
                        } else return [];
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create User',
                            submitButtonName: 'Create User',
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',    //value that must be pre-set but not appear in the request when saving.
                                [combineIntoFormElementName({
                                    componentName: Components.AutocompleteOutsideChips,
                                    filterMetadataKey: 'user_management.user.user_group',
                                })]: { value: '{group_name}', isArray: true, isDirty: true }  //value that must be pre-set and appear in the request when saving 
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                            hideOnEditing: true
                        },
                         ModalWrapper: SendAlertWrapper
                    },
                    {
                        props: {
                            title: 'Bulk User Create for {profileTitle}',
                            buttonText: 'Bulk User Create',
                            submitButtonName: 'Create',
                            onOperationSuccessSnackMessage: 'Successfully created {count} user{countBasedEnding}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            operationType: OperationTypes.BULK_CREATE,
                            requestPath: 'user/_batch',
                            description: <BulkUploadDescription />,
                            multipleResultsPopupId: 'Bulk User Create Failed Results Popup',
                            multipleResultsPopup: (
                                <InformationPopup
                                    modalId={'Bulk User Create Failed Results Popup'}
                                    analyticsCardName={'Bulk User Create Failed Results Popup'}
                                />
                            ),
                            multipleResultsPopupTitle:
                                'The following {numberOfItemsFailedToOperate} users were not created:',
                            maxNumberOfItemsPerOneRequest: 20,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                                [combineIntoFormElementName({
                                    componentName: Components.AutocompleteOutsideChips,
                                    filterMetadataKey: 'user_management.user.user_group',
                                })]: { value: '{group_name}', isArray: true, isDirty: true },
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                            hideOnEditing: true,
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                    {
                        props: {
                            title: 'Add Users',
                            submitButtonName: 'Add',
                            onOperationSuccessSnackMessage: '',
                            entityType: UserPlatformManagementEntityTypes.User_Group,
                            operationType: OperationTypes.BULK_CREATE,
                            doNotShowModalOpenButtonOnScreenerCard: true,
                            useScreenerDataForFilters: true,
                            FormComponent: AddForm,
                        }
                    }
                ],
            },
            {
                screenerProps: {
                    cardName: 'Group Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'group_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['group'] },
                                    'user_management.audit.op': { values: ['UPDATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Added Package Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'group_package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>group_id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['group_package'] },
                                    'user_management.audit.op': { values: ['CREATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Removed Package Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'group_package_old_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.old_record->>group_id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['group_package'] },
                                    'user_management.audit.op': { values: ['DELETE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.Package) {
        return [
            {
                screenerProps: {
                    cardName: 'Entitlements',
                    entityType: UserPlatformManagementEntityTypes.Package_Entitlement,
                    screenerRequestParams: {
                        path: 'package_entitlement',
                        overrideRequestPath: 'package_entitlement',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.package_entitlement.package_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for Entitlement',
                    enableTopLeftActionPanel: true,
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'package_entitlement',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                    showSelectionOnEdit: true,
                    EditButtonsVariant: getEditButtonVariant2({ addButtontitle: 'Add Entitlements' }),
                    operationType: OperationTypes.BULK_EDIT,
                    hardRefreshOnUpdate: true,
                    processDeleteAction: (
                        setValue: UseFormSetValue<FieldValues>,
                        selectedIds: string[],
                        rowLevelFiltersFormKeys?: Record<string, Record<string, string>>
                    ) => {
                        selectedIds.forEach((id) => {
                            const formFilterName = rowLevelFiltersFormKeys?.[id]?.["user_management.lookup_entitlement.is_active"];
                            if (formFilterName)
                                setValue(formFilterName, false, { shouldDirty: true });
                        })
                    },
                    getRowClassRules: (ruleType: AgGridRowClassRules, selectedIds: string[], rowData: any) => {
                        switch (ruleType) {
                            case AgGridRowClassRules.ToBeDeleted:
                                return selectedIds.indexOf(rowData.id) > -1;
                            case AgGridRowClassRules.ToBeAdded:
                                return rowData.newRow;
                            default:
                                return false
                        }
                    },
                    getConfirmationModalContent: (rowsData?: DataItem[], rowsToBeDeleted?: string[]) => {
                        const pillsToBeDeleted: any[] = []
                        if (rowsToBeDeleted?.length) {
                            rowsToBeDeleted.forEach((id) => {
                                const rowData = rowsData?.find(p => p.id?.toString() == id.toString())
                                pillsToBeDeleted.push({
                                    key: rowData?.id,
                                    value: rowData?.lookup_entitlement?.name
                                })
                            })
                        }

                        let pillsToBeAdded: any[] = [];
                        const newRows = rowsData?.filter(p => p.newRow);
                        if (newRows?.length) {
                            pillsToBeAdded = newRows.map(p => ({ key: p?.id, value: p?.lookup_entitlement?.name }));
                        }

                        return (<Box sx={{ width: '100%', paddingTop: '30px' }}>
                            <StyledFormLabel sx={{ lineHeight: 1.2, paddingTop: '10px', paddingBottom: '10px' }}>
                                {'Added Entitlements'}
                            </StyledFormLabel>
                            {
                                pillsToBeAdded.length > 0 ?
                                    pillsToBeAdded
                                        .map(({ key, value }: any) => (
                                            <Chip sx={{ margin: '5px' }} key={key} label={value} />
                                        )) :
                                    <div style={{ marginLeft: '5px' }}>-</div>
                            }
                            <StyledFormLabel sx={{ lineHeight: 1.2, paddingTop: '30px', paddingBottom: '10px' }}>
                                {'Removed Entitlements'}
                            </StyledFormLabel>
                            {
                                pillsToBeDeleted.length > 0 ?
                                    pillsToBeDeleted
                                        .map(({ key, value }: any) => (
                                            <Chip sx={{ margin: '5px' }} key={key} label={value} />
                                        )) :
                                    <div style={{ marginLeft: '5px' }}>-</div>
                            }
                        </Box>)
                    },
                    getFormDataState: (rowsData?: DataItem[], additonData?: {
                        rowsToBeAdded?: any[],
                        externalFormData?: { filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any> }
                    }, formData?: RhFormData) => {

                        const idToDelete: any[] = [];
                        if (formData) {
                            Object.keys(formData || {}).forEach((formKey) => {
                                if (formData?.[formKey] === false) {
                                    const keys = formKey?.split(getDividerString('DIVIDER'));
                                    idToDelete.push(Number.parseInt(keys[keys.length - 1]));
                                }
                            })
                        }

                        let isRowsDataUpdated = false;
                        let updatedRowsData = rowsData?.map(data => {
                            if (idToDelete.includes(data.id) && data.lookup_entitlement.is_active) {
                                data.lookup_entitlement.is_active = false;
                                isRowsDataUpdated = true;
                            }
                            return data;
                        }) || [];

                        if (additonData?.rowsToBeAdded?.length) {
                            updatedRowsData = updatedRowsData.filter(r => !r.newRow); // first only gets which is not newly added before.
                            const lastRow = rowsData?.sort(p => p.id)[rowsData?.length - 1];
                            let id: number = (lastRow?.id || 9999);
                            additonData.rowsToBeAdded.forEach((row) => {
                                const entitlementData: any = additonData?.externalFormData?.filtersData?.data["user_management.package.package_entitlement"].items.find(
                                    (item) => item.key === row.key
                                );
                                const productData: any = additonData?.externalFormData?.filtersData?.data["user_management.lookup_product.value"].items.find(
                                    (item) => item.key === (entitlementData as any).product_lid
                                );

                                updatedRowsData.push({
                                    id,
                                    package_id: lastRow?.package_id,
                                    lookup_entitlement: {
                                        is_active: true,
                                        name: entitlementData.value,
                                        value: entitlementData.lookup_entitlement_value,
                                        description: entitlementData.description,
                                        lookup_id: row.key,
                                        lookup_product: {
                                            value: productData?.value
                                        }
                                    },
                                    newRow: true // keep this flag to use at other places
                                });
                                id++;
                            })

                            const existingDataKeys = rowsData?.filter(rowData => rowData.newRow).map(r => r.lookup_entitlement.lookup_id);
                            isRowsDataUpdated = existingDataKeys?.length ?
                                (existingDataKeys.length !== additonData.rowsToBeAdded.length || existingDataKeys.some(key => !additonData.rowsToBeAdded?.map(p => p.key).includes(key))) :
                                true

                        }
                        const formKey = combineIntoFormElementName({
                            componentName: Components.AutocompleteOutsideChips,
                            filterMetadataKey: "user_management.package.package_entitlement"
                        });

                        return {
                            state: {
                                [formKey]: {
                                    [RequestTypes.DELETE]: idToDelete.map((p) => ({ key: p })),
                                    [RequestTypes.POST]: updatedRowsData
                                        .filter((p) => p.newRow)
                                        .map((p) => ({
                                            key: p.lookup_entitlement.lookup_id,
                                            description: p.lookup_entitlement.description,
                                        })),
                                },
                            },
                            deletion: idToDelete,
                            addition: additonData?.rowsToBeAdded,
                            isRowsDataUpdated,
                            updatedRowsData: updatedRowsData,
                        };
                    },
                    processAddAction: (rowsData, externalFormData) => {
                        const dataToAdd: any[] = [];
                        if (externalFormData.formDataState) {
                            Object.keys(externalFormData.formDataState).forEach((filter) => {
                                const filterObj = externalFormData.formDataState?.[filter];
                                if (filterObj[RequestTypes.POST]) {
                                    filterObj[RequestTypes.POST].forEach((filterItem: { key: number }) => {
                                        const filterDataObj: any = externalFormData.filtersData?.data["user_management.package.package_entitlement"].items.find(
                                            (item) => item.key === filterItem.key
                                        );
                                        if (filterDataObj) {
                                            dataToAdd.push({
                                                'key': filterItem.key,
                                                'description': filterDataObj.description
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        const existingDataKeys = rowsData.filter(rowData => rowData.newRow).map(r => r.lookup_entitlement.lookup_id);
                        
                        return {
                            dataToAdd,
                            hasUpdate: existingDataKeys?.length ?
                                (existingDataKeys.length !== dataToAdd.length || existingDataKeys.some(key => !dataToAdd.map(p => p.key).includes(key))) : true
                        }
                    },
                    getDefaultView: (rowsData: DataItem[], formData: RhFormData) => {
                        Object.keys(formData).forEach(key => formData[key] = true);
                        return {
                            defaultRowsData: rowsData.filter(p => !p.newRow).map(p => {
                                p.lookup_entitlement.is_active = true;
                                return p;
                            }),
                            defaultFormData: formData
                        }
                    },
                    getColumnOrderderedInEditMode: (columnDefs: ColumnDef[]) => {
                        columnDefs.splice(2, 0, columnDefs[columnDefs.length - 1]);
                        return columnDefs.slice(0, -1);
                    }
                },
                modals: [
                    {
                        props: {
                            title: 'Add Entitlements',
                            submitButtonName: 'Add',
                            entityType: UserPlatformManagementEntityTypes.Package_Entitlement,
                            onOperationSuccessSnackMessage: '',
                            onBeforeCreateSuccess: () => { },
                            operationType: OperationTypes.BULK_CREATE,
                            doNotShowModalOpenButtonOnScreenerCard: true,
                            useScreenerDataForFilters: true,
                            FormComponent: AddForm
                        }
                    },
                ]
            },
            {
                screenerProps: {
                    cardName: 'Groups',
                    enableTopRightActionPanel: true,
                    enableTopLeftActionPanel: true,
                    searchPlaceholder: 'Search for Group',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group_package.package_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopRightActionPanel: true,
                    enableTopLeftActionPanel: false,
                    searchPlaceholder: 'Search for User',
                    screenerRequestParams: {
                        path: 'user',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group_package.package_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Package Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['package'] },
                                    'user_management.audit.op': { values: ['UPDATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Added Entitlement Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'package_entitlement',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>package_id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['package_entitlement'] },
                                    'user_management.audit.op': { values: ['CREATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Removed Entitlement Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'package_entitlement_old',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.old_record->>package_id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['package_entitlement'] },
                                    'user_management.audit.op': { values: ['DELETE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            }
        ];
    }
    throw new Error(`getEntityAssociatedItems exception. Pointed invalid entity type - '${entityType}'`);
}

export function getEntityFilterReqParams(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
): SearchByParams {
    let request = {
        path: entityType,
        type: operationType,
        includeData: true,
        requestBody: {
            filters: {
                values: {},
            },
        },
    };

    if (entityType === UserPlatformManagementEntityTypes.User && operationType === OperationTypes.CREATE) {
        request.requestBody.filters.values = {
            'user_management.account.company_name': {
                values: ['-1'],
            },
        };
        return request;
    }
    
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
        request.requestBody.filters.values = {
            'user_management.group.account_id': {
                values: ['{filterPreRequestParamValues.user_group.group.account.id}'],
            }
        };
        return request;
    }

    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_CREATE) {
        request.requestBody.filters.values = {
            'user_management.group.account_id': {
                values: ['{filterPreRequestParamValues.group.account_id}'],
            }
            // 'user_management.user_group.user_disable_date': {
            //     values: [getDateTimeUtcNow(), null]
            // }
        };
        return request;
    }


    if ((
        entityType === UserPlatformManagementEntityTypes.Package_Entitlement &&
        operationType === OperationTypes.BULK_CREATE
    ) || (entityType === UserPlatformManagementEntityTypes.Package &&
        operationType === OperationTypes.CREATE)) {
        request.requestBody.filters.values = {
            'user_management.lookup_product.value': {
                values: ['-1'],
            },
        };
        return request;
    }
    if (
        entityType === UserPlatformManagementEntityTypes.Group_Package &&
        operationType === OperationTypes.BULK_CREATE
    ) {
        request.requestBody.filters.values = {
            'user_management.lookup_product.lookup_id': {
                values: [-1],
            },
        };
        return request;
    }
   

    if (
        [
            UserPlatformManagementEntityTypes.User,
            UserPlatformManagementEntityTypes.Account,
            UserPlatformManagementEntityTypes.Package,
            UserPlatformManagementEntityTypes.Package_Entitlement,
            UserPlatformManagementEntityTypes.Group,
            UserPlatformManagementEntityTypes.User_Alert_Subscription,
            UserPlatformManagementEntityTypes.Group_Package
        ].includes(entityType)
    ) {
        return { path: entityType, type: operationType, includeData: true };
    }

    throw new Error(`getEntityFilterReqParams exception. Pointed invalid entity type - '${entityType}'`);
}

export function getFilterPreRequestParams(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    entityId?: number,
): SearchByParams {
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
        return {
            path: 'user',
            view: 'default',
            requestBody: {
                filters: {
                    values: {
                        'user_management.user.id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    } else if (entityType === UserPlatformManagementEntityTypes.Package_Entitlement && operationType === OperationTypes.BULK_CREATE) {
        return {
            path: 'package_entitlement',
            view: 'package_profile',
            requestBody: {
                filters: {
                    values: {
                        'user_management.package_entitlement.package_id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    else if (entityType === UserPlatformManagementEntityTypes.User_Alert_Subscription && operationType === OperationTypes.CREATE) {
        return {
            path: 'user_alert_subscription',
            view: 'user_notification',
            requestBody: {
                filters: {
                    values: {
                        'user_management.user_alert_subscription.user_id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    else if (entityType === UserPlatformManagementEntityTypes.Group_Package && operationType === OperationTypes.BULK_CREATE) {
        return {
            path: 'group_package',
            view: 'group_profile',
            requestBody: {
                filters: {
                    values: {
                        'user_management.group_package.group_id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    else if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_CREATE){
        return {
            path: 'user_group',
            view: 'user_group',
            requestBody: {
                filters: {
                    values: {
                        'user_management.user_group.group_id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    return {};
}

export function modifyFormRequestBody(
    requestBody: any,
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    entityId?: number,
): any {
    
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType == OperationTypes.BULK_EDIT) {
        if (requestBody[entityType]?.length > 0) {
            requestBody[entityType].forEach((req: any) => {
                req['user_id'] = req['user_id'] || entityId;
            });
        }
        return requestBody[entityType] || requestBody;
    }
    if (entityType === UserPlatformManagementEntityTypes.Package_Entitlement && operationType == OperationTypes.BULK_EDIT) {
        if (requestBody[entityType]?.length > 0) {
            requestBody[entityType].forEach((req: any) => {
                req['package_id'] = req['package_id'] || entityId;
            });
        }
        return requestBody[entityType] || requestBody;
    }
    
    return requestBody;
}

export function getModifyDeleteRequestFunction(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    screenerQueryResult: any,
) {
    return function (requestPath: string, deleteRequest: any) {
        const getRequest = (id: any) => ({
            searchByParams: {
                path: `${requestPath}/${id}`,
                config: {
                    enabled: true,
                },
                noErrorOnNoKeyValuePairs: true,
            },
            requestType: RequestTypes.DELETE,
            invalidate: false,
        });

        if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
            const data = screenerQueryResult.data.results.data[0];
            const deleteEntity = data[UserPlatformManagementEntityTypes.User_Group]?.find(
                (p: any) => p.group.id === deleteRequest.key,
            );
            return getRequest(deleteEntity.id);
        }

        if (
            entityType === UserPlatformManagementEntityTypes.Package_Entitlement &&
            operationType === OperationTypes.BULK_EDIT
        ) {
            const data = screenerQueryResult.data.results.data;
            const deleteEntity = data?.find((p: any) => p.id === deleteRequest.key);
            return getRequest(deleteEntity.id);
        }
        
        if (
            entityType === UserPlatformManagementEntityTypes.Group_Package &&
            operationType === OperationTypes.BULK_EDIT
        ) {
            return getRequest(deleteRequest.key);
        }

        if (entityType===UserPlatformManagementEntityTypes.Group_User && operationType === OperationTypes.BULK_EDIT){
            const data = screenerQueryResult.data.results.data;
            const deleteEntity = data?.find((p: any) => p.id === deleteRequest.key);
            return getRequest(deleteEntity.user_group[0].id);
        }

    };
}

export function getEntityScreenerReqParam(
    entityType: UserPlatformManagementEntityTypes,
    entityId: number,
): SearchByParams {
    const availableEntities: Record<string, Array<string>> = {
        [UserPlatformManagementEntityTypes.Account]: ['account', 'account_profile', 'user_management.account.id'],
        [UserPlatformManagementEntityTypes.User]: ['user', 'user_profile', 'user_management.user.id'],
        [UserPlatformManagementEntityTypes.Group]: ['group', 'account_group', 'user_management.group.id'],
        [UserPlatformManagementEntityTypes.Package]: ['package', 'package_profile', 'user_management.package.id']
    };

    const parameters = availableEntities[entityType];
    if (parameters && parameters.length > 0) {
        return {
            path: parameters[0],
            view: parameters[1],
            requestBody: {
                filters: {
                    values: {
                        [parameters[2]]: {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    throw new Error(`getEntityScreenerReqParam exception. Pointed invalid entity type - '${entityType}'`);
}

export function getRequestBody(
    requestBody: any,
    dirtyFields: any,
    getValues: any,
    entityType?: UserPlatformManagementEntityTypes,
    entityFiltersReqParamsBody?: any
) {
    let body = {} as any;
    let getDirtyValue = (key: string): any => {
        let value = '-1';
        if (key in dirtyFields && getValues(key)) {
            value = getValues(key);
        }
        return value;
    };

    for (let key in requestBody) {
        let arg = requestBody[key].query_arg;
        let keyValues = getDirtyValue(key);

        if (entityType && entityType === UserPlatformManagementEntityTypes.Group_Package) {
            keyValues = keyValues.map((item: any) => item.key);
            if (keyValues.length === 0) keyValues = [-1];
        }

        if (entityType && entityType === UserPlatformManagementEntityTypes.User_Group) {
             
            
            if (keyValues instanceof Array) {
                keyValues = keyValues.map((item: any) => item.key);
                if (keyValues.length === 0) keyValues = undefined;
            }
            else if (typeof keyValues !== 'boolean') {
                keyValues = undefined
            }
        }

        if (entityType && entityType === UserPlatformManagementEntityTypes.Package) {
            keyValues = keyValues.value || -1;
        }
        
        if (keyValues) {
            body[arg] = { values: keyValues instanceof Array ? keyValues : [keyValues] };
        }
    }

    return {
        filters: {
            values: {
                ...(entityFiltersReqParamsBody?.requestBody?.filters?.values || {}),
                ...body,
            },
        },
    };
}
